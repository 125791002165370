import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { OrderFormService } from '../order-form.service';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { OrderSubmittedDialogComponent } from '../order-submitted-dialog/order-submitted-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SimpledialogComponent } from '../simpledialog/simpledialog.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
declare var Stripe;

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  public orderArray = [];
  stripe; // : stripe.Stripe;

  constructor(public analytics: AngularFireAnalytics, private functions: AngularFireFunctions, private dialog: MatDialog, private afs: AngularFirestore, public afAuth: AngularFireAuth, public orderService: OrderFormService, private route: ActivatedRoute) {


    this.afAuth.user.subscribe(val => {


      this.orderArray = [];
      if (val != null) {

        this.afs.collection(`internal/${this.orderService.restaurant.restaurantID}/current_orders`, ref => ref.where('user', '==', val.uid).orderBy('order_iso_time', 'desc')).valueChanges().subscribe(val => {
          // console.log('orders', val);
          this.orderArray = val;
        });

        this.afs.collection(`internal/${this.orderService.restaurant.restaurantID}/order_archive`, ref => ref.where('user', '==', val.uid).orderBy('order_iso_time', 'desc')).valueChanges().subscribe(orders => {
          // console.log('orderArchive', orders);
          orders.forEach(order => {
            this.orderArray.push(order);
          });
          // console.log(this.orderArray);
        });

      }
    });


  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.payment_intent) {
          let pk = this.orderService.mode == 'prod' ? this.orderService.restaurant.livePublishableKey : this.orderService.restaurant.testPublishableKey;
          this.stripe = Stripe(pk);
          this.stripe.retrievePaymentIntent(params.payment_intent_client_secret).then(({ paymentIntent }) => {
            // console.log('paymentIntent', paymentIntent)
          });
          let message = '';
          const dialogRef = this.dialog.open(OrderSubmittedDialogComponent, {
            width: '95vw',
            maxWidth: '500px',
            //disableClose: true,
            data: { submittedMessage: message }
          });
          this.analytics.logEvent('PURCHASE', { value: this.orderService.orderObject.get('total').value });
          this.orderService.resetOrderObject();
        }

      }
      );
    // http://localhost:4200/orderHistory?payment_intent=pi_3JpdzgEswaNcxy5O1Dvf3B1H&payment_intent_client_secret=pi_3JpdzgEswaNcxy5O1Dvf3B1H_secret_8DZl9nkvB53PZ7G2FDXRhOCxp&redirect_status=succeeded

  }
  async signOut() {
    await this.afAuth.signOut();
  }

  toMoment(time) {
    // console.log("time: ",time)
    return moment(time.seconds * 1000).tz(this.orderService.restaurant.timezone).format('LLLL');
  }

}
