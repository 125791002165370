<button class="close" mat-icon-button disabled aria-label="Button to close popup to set order details."
  mat-dialog-close>
  <mat-icon mat-dialog-close>close</mat-icon>
</button>
<div>
  <h1 class="font-weight-normal text-center m-b-8">Order Details</h1>

  <mat-divider></mat-divider>
  <!-- <pre>{{orderService.orderObject.get('deliveryAddress').value | json}}</pre> -->
  <!-- <pre>Touched?: {{orderService.orderObject.touched}}</pre> -->
  <mat-dialog-content class="w-100 m-0" style="padding: 0px !important;">
    <form [formGroup]="orderService.orderObject" #addForm="ngForm">

      <div class="toggle mb-2 m-t-8">
        <p class="txt-light m-b-8">Order Type</p>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="orderType">
          <mat-button-toggle *ngIf='orderService.restaurant.takesDoordash' value="Doordash">Doordash Delivery
          </mat-button-toggle>
          <mat-button-toggle *ngIf='orderService.restaurant.takesDelivery' value="Delivery">Delivery</mat-button-toggle>
          <mat-button-toggle value="Pickup">Pickup</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'">
        <p style="font-weight: lighter;"><span style="font-weight: normal;">Restaurant Address:</span>
          {{orderService.restaurant.address}}</p>

        <div *ngIf="orderService.orderObject.get('deliveryAddress').value"
          style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
          <p style="font-weight: lighter;">Delivery Address:</p>
          <div style="margin-left: 1rem;">
            <div style="display: flex; align-items: center;">
              <b>
                {{ orderService.orderObject.get('deliveryAddress').value}}
              </b>
              <button mat-stroked-button (click)="clearAddress()"
                style="font-weight: bold; margin-left: auto; cursor: pointer; margin-right: 1rem;">Edit</button>
            </div>

            <small style="color: red" *ngIf="orderService.orderObject.get('deliveryAddress').errors?.validAddress">
              Invalid address: Deliveries must be within {{getMaxMiles()}} miles<span
                *ngIf="orderService.restaurant.deliveryAreaToggle"> and within delivery area</span>.
            </small>
            <small style="color: green;" class="mt-0" *ngIf="orderService.orderObject.get('deliveryAddress').value && orderService.orderObject.get('deliveryAddress').valid 
          && !orderService.orderObject.get('deliveryAddress').pending ">
              Address is in range. ✅
            </small>
          </div>

        </div>
        <small class="text-danger">{{specificAddress}}</small>
        <mat-form-field *ngIf="!orderService.orderObject.get('deliveryAddress').value" appearance="fill" class="w-100"
          color="primary" [style.fontSize.px]="14">
          <mat-label>Delivery Address Search</mat-label>
          <input type="text" matInput placeholder="Search for your address... " autofocus="false" google-place
            (onError)="handleSearchError($event)" />
        </mat-form-field>
      </div>

      <!-- for doordash delivery, probably need to get a new address check, since there is not limit on range for doordash******************** -->
      <div *ngIf="orderService.orderObject.get('orderType').value == 'Doordash'">
        <p style="font-weight: lighter;"><span style="font-weight: normal;">Restaurant Address:</span>
          {{orderService.restaurant.address}}</p>

        <div *ngIf="orderService.orderObject.get('deliveryAddress').value"
          style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
          <p style="font-weight: lighter;">Delivery Address:</p>
          <div style="margin-left: 1rem;">
            <div style="display: flex; align-items: center;">
              <b>
                {{ orderService.orderObject.get('deliveryAddress').value}}
              </b>
              <button mat-stroked-button (click)="clearAddress()"
                style="font-weight: bold; margin-left: auto; cursor: pointer; margin-right: 1rem;">Edit</button>
            </div>

            <small style="color: red" *ngIf="orderService.orderObject.get('deliveryAddress').errors?.validAddress">
              Invalid address: Deliveries must be within {{getMaxMiles()}} miles<span
                *ngIf="orderService.restaurant.deliveryAreaToggle"> and within delivery area</span>.
            </small>
            <small style="color: green;" class="mt-0" *ngIf="orderService.orderObject.get('deliveryAddress').value && orderService.orderObject.get('deliveryAddress').valid 
          && !orderService.orderObject.get('deliveryAddress').pending ">
              Address is in range. ✅
            </small>
          </div>
        </div>
        <small class="text-danger">{{specificAddress}}</small>
        <mat-form-field *ngIf="!orderService.orderObject.get('deliveryAddress').value" appearance="fill" class="w-100"
          color="primary" [style.fontSize.px]="14">
          <mat-label>Delivery Address Search</mat-label>
          <input type="text" matInput placeholder="Search for your address... " autofocus="false" google-place
            (onError)="handleSearchError($event)" />
        </mat-form-field>
      </div>




      <mat-progress-bar mode="indeterminate" *ngIf="orderService.loading"></mat-progress-bar>

      <div
        *ngIf="(afAuth.user | async) && orderService.orderObject.get('orderType').value == 'Delivery' && orderService.userDocData?.deliveryAddresses">
        <!-- <pre>{{ orderService.userDocData | json }}</pre> -->
        <p class="upperHint">Or select a saved address</p>
        <p mat-stroked-button *ngFor="let address of orderService.userDocData?.deliveryAddresses"
          (click)="updateAddress(address)" class="addressSelection">{{address}}</p>
      </div>
      <div *ngIf="!(afAuth.user | async) && orderService.orderObject.get('orderType').value == 'Delivery'">
        <!-- <button mat-stroked-button class="text-center m-1" (click)="openLoginDialog()">Login to use a saved address</button> -->
      </div>
      <div
        *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'||orderService.orderObject.get('orderType').value == 'Doordash'"
        class="w-100">
        <form [formGroup]="orderService.orderObject" class="w-50">
          <mat-form-field class="w-100" appearance="fill" color="primary">
            <mat-label>Apt Number</mat-label>
            <input formControlName="aptNum" type="text" matInput placeholder="Apt No./Unit" class="w-100">
          </mat-form-field>
        </form>

        <div class="text-center mb-2" *ngIf="orderService.restaurant.deliveryAreaToggle">
          <a class="txt-light m-b-8" (click)='openDeliveryAreaDialog()'>Delivery Area Map</a>
        </div>

      </div>

      <p *ngIf="orderService.orderObject.get('orderType').value == 'Pickup'" class="text-center mt-2">Estimated Pickup
        Time: {{this.orderService.restaurant.pickupTime}}</p>
      <p *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'" class="text-center mt-2">Estimated
        Delivery
        Time: {{this.orderService.restaurant.deliveryTime}}</p>


      <div class="header warning m-b-16 m-t-16"
        *ngIf="!orderService.canOrderNow && orderService.orderObject.get('orderType').value=='Pickup'">
        <h3 class="txt-bold">We are Closed Right Now</h3>
        <h3 class="txt-bold">Next available Order Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</h3>
      </div>
      <div class="header warning m-b-16 m-t-16"
        *ngIf="!orderService.canOrderNow && orderService.orderObject.get('orderType').value=='Delivery'">
        <p class="txt-bold">We are not Delivering Right Now</p>
        <p class="txt-bold">Next available Delivery Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</p>
      </div>

    </form>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="checkValidDetails()" mat-raised-button color="primary" class="w-100 mt-2">Continue</button>
  </mat-dialog-actions>

</div>