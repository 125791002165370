/// <reference types="@types/googlemaps" />
import { NgZone } from '@angular/core';
import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderFormService } from './order-form.service';
// const google = require('@types/googlemaps');
@Directive({
  selector: '[google-place]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<string> = new EventEmitter();

  private element: HTMLInputElement;

  constructor(private elRef: ElementRef, public orderService: OrderFormService, private zone: NgZone) {
    //elRef will get a reference to the element where
    //the directive is placed
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place) {
    //@params: place - Google Autocomplete place object
    //@returns: location_obj - An address object in human readable format

    let containsStreetNum = false;
    let location_obj: any = {};
    for (let i in place.address_components) {

      let item = place.address_components[i];

      if (item.types.includes('street_number')) {
        containsStreetNum = true
      }

      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf("locality") > -1) {
        location_obj['locality'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
        location_obj['admin_area_l1'] = item['short_name']
      } else if (item['types'].indexOf("street_number") > -1) {
        location_obj['street_number'] = item['short_name']
      } else if (item['types'].indexOf("route") > -1) {
        location_obj['route'] = item['long_name']
      } else if (item['types'].indexOf("country") > -1) {
        location_obj['country'] = item['long_name']
      } else if (item['types'].indexOf("postal_code") > -1) {
        location_obj['postal_code'] = item['short_name']
      }

    }
    // console.log('directive', location_obj);


    if (containsStreetNum) {
      return location_obj;

    } else {
      return 'No Street Number'
    }
  }

  ngOnInit() {
    const options = {
      types: ['address']
    };
    const autocomplete = new google.maps.places.Autocomplete(this.element, options);
    autocomplete.setFields(['address_component', 'formatted_address', 'types'])

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      //Emit the new address object for the updated place


      let location_obj = this.getFormattedAddress(autocomplete.getPlace());
      if (location_obj == 'No Street Number') {
        this.zone.run(() => {
          this.onError.emit('Address is not specific enough or is not on Google yet. ')
        })
      } else {
        this.onError.emit('')
        this.orderService.orderObject.patchValue({ 'deliveryAddress': location_obj.formatted_address });
      }


    });
  }

}
