import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { CouponPageComponent } from './coupon-page/coupon-page.component';
import { TosComponent } from './tos/tos.component';
import { ReservationComponent } from './reservation/reservation.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ShellComponent } from './shell/shell.component';
import { NotfoundComponent } from './notfound/notfound.component';


const routes: Routes = [

    { path: "", component: NotfoundComponent },
    {
        path: ':restaurantId',
        component: ShellComponent,
        children: [
            { path: '', component: MenuComponent },
            { path: 'reservations', component: ReservationComponent },
            { path: 'coupons', component: CouponPageComponent },
            { path: 'checkout', component: CheckoutComponent },
            { path: 'orderHistory', component: OrderHistoryComponent },
            { path: 'Terms-Of-Service', component: TosComponent },
            { path: 'privacy-policy', component: PrivacyComponent },
            { path: 'menu/:group', component: MenuGroupComponent },
        ]
    },


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: "top",
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {

}