import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { OrderFormService } from '../order-form.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  step = 0;
  reservationForm: FormGroup;
  checkinForm: FormGroup;

  denverDate = new Date().toLocaleString("en-US", { timeZone: "America/Denver" })
  minDate = new Date(this.denverDate);
  public maxDate = new Date(this.denverDate);
  submitted = false;
  checkinSubmitted = false;

  reservationLoading = false;
  checkinLoading = false;

  attemptedCheckinSubmit = false;

  peopleInLine = 'Loading...';



  public openTimes = [];
  guestNums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  date$;
  dateFilter;


  constructor(private afs: AngularFirestore, private fb: FormBuilder, public orderService: OrderFormService) {

    let date = moment();

    this.dateFilter = date => !this.orderService.closeDays.includes(date.day());

  }



  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    if (this.reservationForm.get('name').invalid || this.reservationForm.get('phoneNum').invalid || this.reservationForm.get('email').invalid) {
      return
    } else {
      this.step++;

    }
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {

    this.maxDate.setDate(this.minDate.getDate() + 15);

    this.reservationForm = this.fb.group({

      name: ['', [Validators.required]],
      phoneNum: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      date: ['', [Validators.required]],
      time: ['', [Validators.required]],
      comments: ['', [Validators.maxLength(170)]],
      guestCount: [2, [Validators.required]]

    });


    this.checkinForm = this.fb.group({
      agreedToTextMessages: [false, [Validators.requiredTrue]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      guestCount: ['', [Validators.required,  Validators.pattern("^[0-9]*$"), Validators.min(1)]] ,

      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],

    });

    this.reservationForm.get('date').valueChanges.subscribe(val => {
      this.loadTimes(val);
    });

  }


  async submitReservation() {
    this.reservationLoading = true;
    try {
      if (this.reservationForm.invalid) {
        return;
      }
      else {
        this.reservationForm.value.date = new Date(this.reservationForm.value.date.unix() * 1000)
        await this.afs.collection(`reservations/${this.orderService.restaurant.restaurantID}/upcoming`).add({
          ...this.reservationForm.value,
          created: new Date(),
          confirmed: false,
        });

      }
      this.submitted = true;

    } catch (error) {
      console.log(error);
      alert(error)
    }
    finally {
      this.reservationLoading = false;
    }


  }


  async submitCheckin() {
    this.attemptedCheckinSubmit = true;
    this.checkinLoading = true;
    try {
      if (this.checkinForm.invalid) {
        console.log('hello')


        return;
      }
      else {
        await this.afs.collection(`checkins/${this.orderService.restaurant.restaurantID}/checkins`).add({
          ...this.checkinForm.value,
          created: new Date(),
          tableReady: false,

        });

      }
      this.checkinSubmitted = true;

    } catch (error) {
      console.log(error);
      alert(error)
    }
    finally {
      this.checkinLoading = false;
    }


  }

  loadTimes(val) {
    const denverTime = new Date().toLocaleString("en-US", { timeZone: "America/Denver" });
    let currentDate = new Date(denverTime);
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let newHours = hours % 12;
    newHours = newHours ? newHours : 12;
    let ampm = hours >= 12 ? 'pm' : 'am';
    let futureAmpm = (hours + 1) >= 12 ? 'pm' : 'am';
    let futureHours = (hours + 1) % 12;
    futureHours = futureHours ? futureHours : 12;

    let selectedDate = new Date(val);
    let today = new Date(denverTime);
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);


    if (selectedDate.getTime() == today.getTime()) {
      // same day
      let timeList = this.orderService.orderTimeMap[selectedDate.getDay()];
      let firstAvailableTime = this.orderService.subtractHalfHour(timeList[0]);
      let firstAvailableHour = firstAvailableTime[0];

      let firstAvailableMinute = firstAvailableTime[1];

      let lastAvailableTime = this.orderService.subtractHalfHour(timeList[timeList.length - 1]);
      let lastAvailableHour = lastAvailableTime[0];
      let lastAvailableMinute = lastAvailableTime[1];



      /////// not during the order time////////////
      if ((currentDate.getHours() < firstAvailableHour ||
        (currentDate.getHours() == firstAvailableHour && currentDate.getMinutes() < firstAvailableMinute) ||
        currentDate.getHours() > lastAvailableHour ||
        (currentDate.getHours() == lastAvailableHour && currentDate.getMinutes > lastAvailableMinute))
      ) {
        this.openTimes = this.orderService.orderTimeMap[currentDate.getDay()];
      } else {
        let tempTime = this.orderService.convertTimeToString(currentDate);
        let index = this.orderService.orderTimeMap[currentDate.getDay()].indexOf(tempTime);
        let tempList = Array.from(this.orderService.orderTimeMap[currentDate.getDay()]);
        if (index != 0) {
          tempList.splice(0, index - 1);
        }
        this.openTimes = tempList;

      }

    }
    else {

      // different day
      this.openTimes = this.orderService.orderTimeMap[selectedDate.getDay()];

    }
  }

}
