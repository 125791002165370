import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderFormService } from '../order-form.service';
import { MatDialog } from '@angular/material/dialog';
import { AddToOrderDialogComponent } from '../add-to-order-dialog/add-to-order-dialog.component';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss']
})
export class MenuGroupComponent implements OnInit {
  @Input() group: string;
  

  constructor(public dialog: MatDialog, public router: Router, public orderService: OrderFormService, public httpClient:HttpClient) {

    // console.log(router.url)
   
  }

  ngOnInit(): void {
  }

  returnGroupArray(group) {
    // console.log(this.menuList);
    return this.orderService.menuList.filter(menuItem => menuItem.group == group);
  }


  openItem(menuItem) {
    // console.log("menuItem: ",menuItem)

    const dialogRef = this.dialog.open(AddToOrderDialogComponent, {
      autoFocus: false,
      width: '400px',
      maxWidth: '95vw',
      maxHeight: '100vh',
      height:"auto",   
      data: { menuItem }
    });

  }

  getPicURL(g) {
    return `../../assets/images/${this.orderService.restaurant.restaurantID}/${g}.jpg`
  }

  
  // fs.stat(path, (exists) => {
  //   if (exists == null) {
  //       return true;
  //   } else if (exists.code === 'ENOENT') {
  //       return false;
  //   }
  // });


  getItemPicURL(itemName: string) {
    return `../../assets/images/${this.orderService.restaurant.restaurantID}/${itemName}.jpg`
  }

}
