import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Restaurant } from '../Restaurant.model';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  restaurants: Array<Restaurant> = [];
  constructor(public afs: AngularFirestore) {
    afs.collection('restaurants').get().subscribe(docs => {
      docs.forEach(doc => {
        this.restaurants.push(doc.data() as Restaurant)
      })
    })
  }

  ngOnInit(): void {
  }

}
