<div mat-dialog-title>

    <mat-form-field class="example-full-width">
      <input type="text" placeholder="Search for Item..." aria-label="Number" matInput [formControl]="itemControl" matInput [matAutocomplete]="auto">
      <mat-autocomplete (optionSelected)='openDialog(getItem($event.option.value))' #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

</div>
<div mat-dialog-content>
  <h3>Jump to a group</h3>
  <div>
    <button mat-stroked-button class="group" *ngFor="let group of this.orderService.groups" (click)="goToGroup(group)"> {{group}} </button>
  </div>
</div>
