// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    //prod
    apiKey: "AIzaSyCdrc-RHOFtyoDaVnxndv8wFzxMNTMtaS8",
    authDomain: "in-house-prod.firebaseapp.com",
    databaseURL: "https://in-house-prod.firebaseio.com", 
    projectId: "in-house-prod",
    storageBucket: "in-house-prod.appspot.com",
    messagingSenderId: "29346191873",
    appId: "1:29346191873:web:01468e23be6de54a487e00",
    measurementId: "G-1JREMFK5MQ"
    //dev
    // apiKey: "AIzaSyCg1lwg3lIVR_5yH3hVNy5N17OdEbWvV4s",
    // authDomain: "inhouse-dev-ddaad.firebaseapp.com",
    // databaseURL: "https://inhouse-dev-ddaad.firebaseio.com",
    // projectId: "inhouse-dev-ddaad",
    // storageBucket: "inhouse-dev-ddaad.appspot.com",
    // appId: "1:140381093941:web:e7fd107a199ba97f2e717c",
    // measurementId: "G-5C9WG7MLGD"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
