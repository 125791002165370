import { Component, OnInit } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutDialogComponent } from '../checkout-dialog/checkout-dialog.component';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ClosedComponent } from '../closed/closed.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-coupon-dialog',
  templateUrl: './coupon-dialog.component.html',
  styleUrls: ['./coupon-dialog.component.scss']
})
export class CouponDialogComponent implements OnInit {
  // allowedItems = [];
  // disableConfirm = true;
  // qualified = false;
  // public menuItemForm: FormGroup;
  // constructor(private fb: FormBuilder, public orderService: OrderFormService, public dialogRef: MatDialogRef<CouponDialogComponent>, ) {
  //   this.menuItemForm = this.fb.group({
  //     name: ['', [Validators.required]],
  //     size: 'Coupon Item',
  //     group: 'coupon',
  //     quantity: 1,
  //     instructions: '',
  //     price: 0,
  //   });
  //   this.menuItemForm.valueChanges.subscribe(val => {
  //     if (val.name != '') {
  //       console.log('already in here !!')
  //       this.disableConfirm = false;
  //     }
  //   })
  //   if (orderService.coupon.type == "freeItems") {
  //     for (const item in orderService.coupon.totalBound) {
  //       console.log('coupon item: ', item)
  //       if (orderService.orderObject.get('total').value > orderService.coupon.totalBound[item].total) {
  //         this.allowedItems.push(orderService.coupon.totalBound[item].item)
  //         //this.orderService.orderObject.patchValue({ coupon: true });
  //       }
  //     }
  //   }
  //   else if (orderService.coupon.type == "amountDiscount") {//amount discount
  //     //1.check if the total is more than the total bound
  //     this.disableConfirm = false;
  //     for (const item in orderService.coupon.totalBound) {
  //       if (orderService.orderObject.get('total').value > orderService.coupon.totalBound[item].total) {
  //         this.orderService.orderObject.patchValue({ coupon: true });
  //         this.qualified = true;
  //         //newTotal-=orderService.coupon.totalBound[item];
  //         console.log('amount ::::', orderService.coupon.totalBound[item])
  //         // orderService.orderObject.patchValue({ 'total': newTotal });
  //         orderService.orderObject.patchValue({ 'discount': -1 * parseInt(orderService.coupon.totalBound[item].item) });
  //         orderService.couponApplied = true;
  //         orderService.calculateTotal();

  //       }
  //     }
  //     //2. if yes, total minus the discount amount
  //     //3. show the coupon one position before total
  //     //4. set orderService.couponApplied true

  //   }
  //   else {//percent discount
  //     //1.check if the total is more than the totalBound
  //     //2. if yes, total multiply the discount percent
  //     //3. show the coupon is applied
  //     //4. set orderService.couponApplied true
  //     this.disableConfirm = false;
  //     let orderTotal=orderService.orderObject.get('total').value;
  //     console.log('orderTotal: ',orderTotal);
      
  //     for (const item in orderService.coupon.totalBound) {
  //       console.log('orderService.coupon.totalBound[item].total: ',orderService.coupon.totalBound[item].total);
  //       if (orderTotal> orderService.coupon.totalBound[item].total) {
  //         this.orderService.orderObject.patchValue({ coupon: true });
  //         this.qualified = true;
  //         //newTotal-=orderService.coupon.totalBound[item];
  //         console.log('amount ::::', orderService.coupon.totalBound[item]);
  //         let discountAmount=orderTotal*parseFloat(orderService.coupon.totalBound[item].item);
  //         // orderService.orderObject.patchValue({ 'total': newTotal });
  //         orderService.orderObject.patchValue({ 'discount': -1 * discountAmount});
  //         orderService.couponApplied = true;
  //         orderService.calculateTotal();

  //       }
  //     }
  //   }

  // }

  ngOnInit() {
  }

  // addToCart() {

  //   if (this.menuItemForm.invalid) {
  //     return;
  //   }

  //   // console.log('item being pushed', this.menuItemForm.value);
  //   this.orderService.addToCart(this.menuItemForm.value);
  //   this.dialogRef.close();

  // }
  // close() {


  //   // console.log('item being pushed', this.menuItemForm.value);   
  //   this.dialogRef.close();

  // }
}
