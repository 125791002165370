<button class="close" mat-icon-button disabled aria-label="Button to close popup to set order details."
    mat-dialog-close>
    <mat-icon mat-dialog-close>close</mat-icon>
</button>
<div >
    <h1 class="font-weight-normal text-center m-b-8">Delivery Area</h1>

    <div  style="margin-top: 3rem;">
        <google-map style="margin-top: 3rem;" [options]="mapOptions" height="500px" width="100%" [center]="center" [zoom]="zoom">
            <map-marker [position]="center" [options]="markerOptions"></map-marker>
        </google-map>
    </div>

</div>