<div class="couponContainer" *ngIf="orderService.restaurant">
  <div *ngFor="let coupon of orderService.restaurant.coupons ">
    <div *ngIf="coupon.active" class="coupon">
      <h3 class="text-center">{{coupon.title}}</h3>
      <p>{{coupon.description}}</p>
      <!-- <mat-form-field class="w-100" *ngIf="coupon.type == 'freeItem' && coupon.freeItems.length > 1">
                <mat-label>Choose free item: </mat-label>
            <mat-select [(ngModel)]="orderService.couponSelections[coupon.couponId]">
                  <mat-option *ngFor="let item of coupon.freeItems" [value]="item">
                    {{item}}
                  </mat-option>
                </mat-select>
      </mat-form-field>
    <button mat-raised-button class="w-100 mt-4" color="accent" (click)="orderService.addCoupon(coupon)">Add Coupon</button> -->
    </div>

  </div>
  <mat-card *ngIf="!orderService.restaurant.coupons.length">
    <p class="fs-24 text-lighter p-t-16 p-b-16">There are no coupons at this time. </p>
  </mat-card>
</div>