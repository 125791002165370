<div class="container">
  <mat-card class="card mt-3">
    <div *ngIf="(afAuth.user | async) as user else login">


      <div class="row mb-3">
        <p>Signed In as: {{user.email}}</p>
        <button mat-stroked-button color="warn" (click)="signOut()">Sign Out</button>

      </div>
      <h3 class="text-center m-1">Order History</h3>
      <mat-card *ngFor="let order of orderArray" class="mb-2 itemDetail mat-elevation-z5">
        <div class="topDetail">
          <div class="">
            <p *ngIf="order.canceled" class="text-danger">Order Was Canceled</p>
            <p><b># {{ order.id }}. {{order.orderType}}</b></p>
            <p *ngIf="order.isFutureOrder" style="color:#e07f11;"><b>Schedule at
                {{toMoment(order.futureOrderDateTime)}}</b></p>
            <p>{{ order.first | titlecase}} {{ order.last | titlecase}}</p>
            <p *ngIf="order.deliveryID">Doordash Delivery ID: {{ order.deliveryID}}</p>
            <p class="mb-0">{{order.deliveryAddress}}</p>
            <span *ngIf="order.aptNum!=''"> Apt: {{order.aptNum}}
            </span>
            <p class="mb-0">Phone: {{ order.phoneNum }}</p>
            <p class="mb-0">Order Instructions: {{order.orderInstructions}}</p>
            <div *ngIf="order.trackingURL">
              <a href="{{order.trackingURL}}">Track Your Order</a>
            </div>
          </div>
        </div>
        <div class="m-4">
          <ol>
            <div *ngFor="let item of order.items">
              <div class="row">
                <div>
                  <p><b><span>{{item.quantity}} x</span> {{ item.name }} </b></p>
                  <!-- Start Modifiers -->
                  <!-- HARD CODED ITEMS -->
                  <p class="indent"><b>{{item.size}}</b></p>
                  <!-- Dynamic Modifiers -->
                  <p *ngFor="let mod of orderService.modifiers" class="indent">
                    <b *ngIf="item[mod.name]">
                      <span *ngIf="mod.type == 'multi-dropdown'">Add: </span><span
                        *ngIf="mod.type == 'incrementor'">{{mod.name}}:
                      </span> <span *ngIf="mod.type != 'boolean'">{{ item[mod.name] }}</span><span
                        *ngIf="mod.type == 'boolean'">{{ mod.displayName }}</span></b>
                  </p>
                  <p *ngIf="item.couponItem == true">Coupon Item</p>
                  <p class="indent" *ngIf="item.instructions"><b>Instructions: {{item.instructions}}</b></p>
                </div>
                <p class="text-right"> {{ item.price | currency }} </p>
              </div>
              <!-- <pre>{{ item | json }}</pre> -->
              <!-- End Modifiers -->
              <hr>
            </div>
            <p class="mt-2 text-right">SubTotal: &nbsp;{{ order.subTotal | currency }}</p>
            <p class="mb-0 text-right">Tax: &nbsp;{{ order.tax | currency }}</p>
            <p class="mb-0 text-right">Tip:&nbsp; {{ order.tipAmount | currency }}</p>
            <p class="mb-0 text-right">Convenience Fee:
              &nbsp;{{ order.convenienceFee | currency }}</p>
            <div *ngIf='order.additionalFees.length!=0' class="addFees">
              <p class="mb-0 text-right" *ngFor="let additionalFee of order.additionalFees">
                {{additionalFee.displayName}}: {{additionalFee.value | currency}}
              </p>
            </div>
            <div *ngIf='order.orderType== "Doordash"' class="addFees">
              <p class="mb-0 text-right">
                Doordash Fees: {{ orderService.restaurant.doordashFee | currency}}
              </p>
            </div>

            <p class="mb-0 text-right" *ngIf="order.discount != 0"> Discount: {{order.discount | currency}}
            </p>
            <p class="mb-0 text-right">Total: &nbsp;{{ order.total | currency }}</p>
          </ol>
        </div>


      </mat-card>













    </div>
    <ng-template #login>
      <app-login></app-login>
    </ng-template>
    <!-- <pre *ngIf="(afAuth.user | async) as user " style="text-align: left;"> {{ user | json }}</pre> -->
  </mat-card>

</div>