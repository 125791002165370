import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { OrderFormService } from "../order-form.service";
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { OrderSubmittedDialogComponent } from "../order-submitted-dialog/order-submitted-dialog.component";
import { Moment } from 'moment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SimpledialogComponent } from '../simpledialog/simpledialog.component';
import { FormArray, FormControl } from '@angular/forms';
declare var Stripe;

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss']
})
export class CheckoutDialogComponent implements OnInit, AfterViewInit {
  amount = 100;



  constructor(public analytics: AngularFireAnalytics, private dialog: MatDialog, private functions: AngularFireFunctions, public orderService: OrderFormService,
    private router: Router) {
    analytics.logEvent('started_checkout_dialog');

    this.amount = Math.round(this.orderService.orderObject.get('total').value * 100);
    // console.log("this.orderService.orderObject: ", this.orderService.orderObject.value)

  }

  @ViewChild('cardElement') cardElement: ElementRef;

  paymentRequest: any;
  prButton: any;
  elements: any;
  stripe; // : stripe.Stripe;
  card;
  cardErrors;

  loading = false;
  confirmation;
  timeLeft: number = 30;
  interval;
  processOverTime = false;

  canUseWallet = false;
  ngOnInit() {


  }
  async ngAfterViewInit() {
    let orderData;

    try {
      this.loading = true;
      if (this.orderService.mode == 'test') {
        this.stripe = Stripe(this.orderService.restaurant.testPublishableKey);
      } else {
        this.stripe = Stripe(this.orderService.restaurant.livePublishableKey);
      }

      // update future order time 
      if (this.orderService.orderObject.get('isFutureOrder').value == true) {
        const date = (this.orderService.orderObject.get('futureOrderDateTime').value as Moment).unix() * 1000;
        const time = this.orderService.orderObject.get('futureOrderDateTime').value.format('hh:mm a');
        this.orderService.orderObject.patchValue({ 'futureOrderDate': date, 'futureOrderTime': time });
      }

      //remove the unwanted additional fees in order object
      if (this.orderService.orderObject.get('additionalFees').value) {

        let activeFees = <FormArray>this.orderService.orderObject.get('additionalFees');
        // let temp = <FormArray>this.orderObject.get('additionalFees');
        let rmIndex = [];//for storing the index need to be remove
        for (let i = 0; i < activeFees.value.length; i++) {
          const element = activeFees.value[i];
          if (element.orderType != this.orderService.orderObject.get('orderType').value) {
            rmIndex.push(i)
          }
        }
        // console.log("rmIndex", rmIndex)
        for (let j = rmIndex.length - 1; j >= 0; j--) {
          const element = rmIndex[j];
          // console.log("j", j)
          // console.log(element)
          activeFees.removeAt(element);
        }

        // console.log('activeFees:', activeFees)
        // this.orderService.orderObject.patchValue({ 'additionalFees': activeFees });
        // console.log('activeFees:', activeFees)
        // console.log('orderObject value after change', this.orderService.orderObject.value)
      }


      orderData = this.orderService.orderObject.value;
      if (this.orderService.orderObject.get('isFutureOrder').value == true) {
        orderData['futureOrderDateTime'] = this.orderService.orderObject.get('futureOrderDateTime').value.unix();
      } else {
        orderData['futureOrderDateTime'] = ''
      }

      const fun = this.functions.httpsCallable('stripe_create_payment_intent');

      let paymentIntent = await fun({ order: orderData }).toPromise();
      // console.log('paymentIntent response', paymentIntent)

      const options = {
        business: {
          name: this.orderService.restaurant.displayName
        },
        fields: {
          billingDetails: {
            address: {
              line1: 'auto',
              line2: 'auto',
              city: 'auto',
              state: 'auto',
              country: 'auto',
              postalCode: 'auto',
            },
            name: 'auto',
            email: 'auto',
            phone: 'auto'
          }
        },
        terms: {
          card: 'always'
        },

      };
      const appearance = {
        theme: 'stripe',
      };
      const elements = this.stripe.elements({ clientSecret: paymentIntent.client_secret, appearance });
      this.card = elements.create("payment", options);
      this.card.mount(this.cardElement.nativeElement);
      this.loading = false;

      this.card.addEventListener('change', ({ error }) => {
        this.cardErrors = error && error.message;
      });

      this.elements = elements;
    } catch (error) {
      this.loading = false;
      // console.log(error)
      this.dialog.closeAll()
      this.dialog.open(SimpledialogComponent, {
        data: {
          title: 'Error',
          message: `Uh oh, something went wrong. Please try again later.`,
          errorMessage: error.message || error
        }
      });
      await this.reportError(orderData, error)

    }




  }


  async submitCharge(e) {
    try {
      this.loading = true;
      this.startTimer();
      e.preventDefault();
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.origin + "/" + this.orderService.restaurant.restaurantID + "/orderHistory",
        },
      });

      // console.log("calling doordash")
      // console.log(this.orderService.orderObject.value)
      // if (this.orderService.orderObject.get('orderType').value == 'Doordash') {
      //   const Doordash_fun = this.functions.httpsCallable('createDoordashDelivery');

      //   let DD_delivery = await Doordash_fun({ order: this.orderService.orderObject.value }).toPromise();
      //   console.log('create delivery response', DD_delivery)
      // }
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          // console.log(error);
          this.cardErrors = error.message
        } else {
          console.log("An unexpected error occured. Please try again");
          this.cardErrors = error.message || error
        }
      } else {


      }
    } catch (error) {
      console.log('error', error)
    }
    finally {
      this.loading = false;
      this.processOverTime = false;

    }
  }

  async reportError(orderData, err) {
    // console.log('reporting error')

    try {
      let data = {
        error: { message: err.message, rawError: { ...err } },
        orderObject: JSON.stringify(orderData),
        user: '123',
      }
      // console.log('reporting error', data)

      // console.log('data', data)
      let reporter = this.functions.httpsCallable('report_error');
      let response = await reporter(data).toPromise();
      // console.log('response', response)
    } catch (error) {
      console.log('error', error)
    }
  }


  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        console.log('overtime!!!!!')
        this.processOverTime = true;
      }
    }, 1000)
  }

}