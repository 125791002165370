import { Component, OnInit, AfterContentInit } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements AfterContentInit {

  constructor(public orderService: OrderFormService) { }

  ngAfterContentInit(): void {


  }

  public getPicURL(pic) {
    return `assets/images/${this.orderService.restaurant.restaurantID}/${pic.groupName}.jpg`
  }

  public slideIndex = 0;

  public plusSlides(n) {
    this.slideIndex += n;
  }





}
